<template>
  <div v-if="!isAllowPage" class="pt-5 pb-3 w-50 m-auto">
    <security-banner></security-banner>
  </div>
  <page-body v-else class="mx-lg renewalForm">
    <section class="section-1 bg-block mb-4">
      <h4>{{ translations.tcMembershipRenewal }}</h4>
      <h1 class="sm mb-4">
        <div class="detail" v-if="isDuesSeason && paymentInfo.paidFlag">
          <h4>{{ translations.tcDuesArePaid }}</h4>
        </div>
        <div class="detail" v-if="!isDuesSeason">
          <h4>{{ translations.tcOutOfDuesSeason.replace('{0}', fiscalYear.toString()) }}</h4>
        </div>
        <div class="detail" v-if="isPendingDropDate">
          <h4>{{ translations.tcDuesAreInDefault.replace('{0}', paymentInfo.mbrPendingDropDate) }}</h4>
        </div>
      </h1>
      <div class="body">
        <!-- STEP 1 Will not be shown when URL has Monthly-->
        <div id="Step1" v-if="currentStep === 1">
          <div>
            <div>
              <div v-if="(dues.membertype === 'Gideon Member' && !dues.paidflag) ||
                (!!individualProfile.spouse_ind_key &&
                  dues.membertype === 'Auxiliary Member' &&
                  !individualProfile.spouse_paid_flag)
              ">
                <b-form-group>
                  <b-form-radio-group id="rdbGideonPayment" v-model="dues.gideonPayment" :options="optionsYesNoGideon"
                    name="rdbGideonPayment"></b-form-radio-group>
                  ${{ translations.tcGideonDuesAmount }}
                  {{
                    dues.membertype === 'Gideon Member'
                      ? individualProfile.formal_name
                      : individualProfile.spouse_formal_name
                  }}
                </b-form-group>
              </div>
              <div v-if="(dues.membertype === 'Auxiliary Member' && !dues.paidflag) ||
                (!!individualProfile.spouse_ind_key &&
                  !!individualProfile.spouse_member_type_key &&
                  dues.membertype === 'Gideon Member' &&
                  !individualProfile.spouse_paid_flag)
              ">
                <b-form-group>
                  <b-form-radio-group id="rdbAuxPayment" v-model="dues.auxPayment" :options="optionsYesNo"
                    name="rdbAuxPayment"></b-form-radio-group>
                  ${{ translations.tcAuxiliaryDuesAmount }}
                  {{
                    dues.membertype === 'Auxiliary Member'
                      ? individualProfile.formal_name
                      : individualProfile.spouse_formal_name
                  }}
                </b-form-group>
              </div>
            </div>
            <div class="divDivider">
              <hr />
            </div>
            <div>
              <p>{{ translations.tcMembershipAffirmation1 }}</p>
              <p class="mt-2">{{ translations.tcMembershipAffirmation2 }}</p>
            </div>
          </div>
          <div class="mt-2" style="padding-top: 30px;">
            <b-button @click="handleNext1Click" variant="primary" class="mr-3 w-100-sd mb-3 mb-sm-0">{{
              translations.tcNext }}
            </b-button>
            <b-button @click="handleCancelClick" variant="tertiary" class="w-100-sd mb-sm-0">{{
              translations.tcCancel
            }}</b-button>
          </div>
        </div>
        <!-- /STEP 1 -->
        <!-- STEP 2 -->
        <div id="Step2" v-if="currentStep === 2">
          <div>
            <b-row>
              <b-col sm="6">
                <b-form-group class="form-element">
                  <p class="mb-2">
                    {{ translations.tcSelectNextButtonWhenCompleted }}
                    <br /><span v-if="!isMonthly">{{ translations.tcPaymentPageIsSecureDescription }}</span>
                  </p>
                  <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb" v-if="!isMonthly">
                    {{ translations.tcTotalPaymentAmount }}
                  </h3>
                  <span v-if="!isMonthly">{{ formatDollars(memberTotalPayment) }}</span>
                  <!-- Payment Type -->
                  <div class="mb-3" v-else>
                    <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb mb-3">
                      <span class="strong_label">Payment Details:</span>
                      {{ formatDollars(memberTotalPayment) }} Monthly

                      <div class="detailsTopMargin" v-if="dues.withdrawDate === 0">
                        <span class="strong_label">Select Date:</span>
                        <div class="mb-3 radio-group-date" style="display: inline-flex;">
                          <b-form-radio-group id="rdbAuxPayment" v-model="dues.withdrawDate"
                            :options="optionsTransactionDate" name="rdbAuxPayment" stacked></b-form-radio-group>
                        </div>
                      </div>

                      <div class="detailsTopMargin" v-else>
                        <span class="strong_label">Selected Withdrawal Date:</span>
                        {{ dues.withdrawDate + (dues.withdrawDate === 1 ? 'st' : 'th') + ' of the Month' }}
                      </div>

                    </h3>
                  </div>
                  <!-- Payment Type -->
                  <div class="mb-3"
                    :class="{ 'error-boxWrap': $v.dues.paymenttype.$error && $v.dues.paymenttype.$dirty }">
                    <h3 class="flex-2 mr-3 text-left font-style-2 mt-3 font-style-2--pb">
                      {{ translations.tcPaymentType }}
                    </h3>
                    <b-form-select v-model="dues.paymenttype" @blur="$v.dues.paymenttype.$touch"
                      :options="optionsPaymentTypes" class="form-control g-select flex-0 mr-3">
                      <template slot="first"></template>
                    </b-form-select>
                    <span v-if="$v.dues.paymenttype.$error && $v.dues.paymenttype.$dirty">Please select payment
                      type.</span>
                  </div>

                  <!-- CREDIT CARD -->
                  <template v-if="dues.paymenttype === 'CCD'">

                    <b-row v-if="!!donorCardsOnFile[0]">
                      <b-form-group class="form-element col-sm-5">
                        <div class="form-div" style="margin-bottom: 20px">
                          <div class="label-strong" v-if="!userImpersonation">Choose from these cards</div>
                          <CreditCardsOnFile :PaymentInstruments="donorCardsOnFile"
                            @handleCardSelection="handleCardSelection($event)" />
                        </div>
                      </b-form-group>
                    </b-row>
                    <b-row v-if="!!donorCardsOnFile[0]">
                      <div class="label-strong" style="margin-left: 17px;">
                        <!-- <input type="radio" name="avcard" class="mb-2 mr-sm-2 mb-sm-0" :checked="isNewCCSelected"
                          @change="handleCardNumberClick()" id="label100" />&nbsp; -->
                        <label for="label100" v-if="!userImpersonation">or, enter a new Credit Card</label>
                      </div>
                    </b-row>
                    <div>
                      <div class="mb-3"
                        :class="{ 'error-boxWrap': $v.dues.cardnumber.$error && $v.dues.cardnumber.$dirty && isNewCCSelected }">
                        <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">
                          {{ translations.tcCreditCardNumber }}
                        </h3>
                        <b-form-input id="type-text6" v-model="dues.cardnumber" @blur="$v.dues.cardnumber.$touch"
                          class="flex-0 g-select" type="text" :placeholder="dues.cardNumberDisplayField" maxlength="75"
                          @click="handleCardNumberClick()"></b-form-input>
                        <span v-if="$v.dues.cardnumber.$dirty && isNewCCSelected">
                          <span v-if="!$v.dues.cardnumber.required">{{ errorMsg.reqField }}</span>
                          <span v-if="!$v.dues.cardnumber.numeric">Invalid card number</span>
                          <span v-if="!$v.dues.cardnumber.minLength">At least 15 digits are required</span>
                        </span>
                      </div>
                      <div class="mb-3"
                        :class="{ 'error-boxWrap': $v.dues.nameonaccount.$error && $v.dues.nameonaccount.$dirty && isNewCCSelected }">
                        <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">
                          {{ translations.tcNameOnCard }}
                        </h3>
                        <b-form-input id="type-text7" v-model="dues.nameonaccount" @blur="$v.dues.nameonaccount.$touch"
                          class="flex-0 g-select" type="text" maxlength="75"></b-form-input>
                        <span v-if="$v.dues.nameonaccount.$error && $v.dues.nameonaccount.$dirty && isNewCCSelected">{{
                          errorMsg.reqField
                        }}</span>
                      </div>
                      <div class="mb-3"
                        :class="{ 'error-boxWrap': $v.dues.expiremonth.$error && $v.dues.expiremonth.$dirty && isNewCCSelected }">
                        <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">
                          {{ translations.tcExpirationMonth }}
                        </h3>
                        <b-form-select v-model="dues.expiremonth" @blur="$v.dues.expiremonth.$touch"
                          :options="optionsExpireMonth" class="form-control g-select flex-0 mr-3">
                          <template slot="first"></template>
                        </b-form-select>
                        <span v-if="$v.dues.expiremonth.$error && $v.dues.expiremonth.$dirty && isNewCCSelected">{{
                          !$v.dues.expiremonth.isExpiryValid ? 'Expiry month cannot be in the past' : errorMsg.reqField
                        }}</span>
                      </div>
                      <div class="mb-3"
                        :class="{ 'error-boxWrap': $v.dues.expireyear.$error && $v.dues.expireyear.$dirty && isNewCCSelected }">
                        <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">
                          {{ translations.tcExpirationYear }}
                        </h3>
                        <b-form-select v-model="dues.expireyear" @blur="$v.dues.expireyear.$touch"
                          :options="optionsExpireYear" class="form-control g-select flex-0 mr-3"
                          @change="$v.dues.expireyear.$touch()">
                          <template slot="first"></template>
                        </b-form-select>
                        <span v-if="$v.dues.expireyear.$error && $v.dues.expireyear.$dirty && isNewCCSelected">{{
                          !$v.dues.expireyear.isExpiryValid ? 'Expiry year cannot be in the past' : errorMsg.reqField
                        }}</span>
                      </div>
                      <div class="mb-3" :class="{ 'error-boxWrap': $v.dues.cardcvc.$error && $v.dues.cardcvc.$dirty }">
                        <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">CVC</h3>
                        <b-form-input id="type-text8" v-model="dues.cardcvc" @blur="$v.dues.cardcvc.$touch"
                          class="flex-0 g-select inputCVC" type="text" maxlength="4"></b-form-input>
                        <span v-if="$v.dues.cardcvc.$error && $v.dues.cardcvc.$dirty && isNewCCSelected">{{
                          errorMsg.reqField }}</span>
                      </div>
                    </div>
                  </template>
                  <!-- /CREDIT CARD -->
                  <!-- ACH -->
                  <template v-if="dues.paymenttype === 'ACH'">
                    <div class="mb-3"
                      :class="{ 'error-boxWrap': $v.dues.nameonaccount.$error && $v.dues.nameonaccount.$dirty }">
                      <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">
                        {{ translations.tcNameOnAccount }}
                      </h3>
                      <b-form-input id="type-text12" v-model="dues.nameonaccount" @blur="$v.dues.nameonaccount.$touch"
                        class="flex-0 g-select" type="text" maxlength="75"></b-form-input>
                      <span v-if="$v.dues.nameonaccount.$error && $v.dues.nameonaccount.$dirty">{{
                        errorMsg.reqField
                      }}</span>
                    </div>
                    <div class="mb-3"
                      :class="{ 'error-boxWrap': $v.dues.routingnumber.$error && $v.dues.routingnumber.$dirty }">
                      <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">
                        {{ translations.tcBankRoutingNumber }}
                      </h3>
                      <b-form-input id="type-text13" v-model="dues.routingnumber" @blur="$v.dues.routingnumber.$touch"
                        class="flex-0 g-select" type="text" maxlength="75"
                        :placeholder="dues.routingnumberDisplay"></b-form-input>
                      <span v-if="$v.dues.routingnumber.$error && $v.dues.routingnumber.$dirty">{{
                        errorMsg.reqField
                      }}</span>
                    </div>
                    <div class="mb-3"
                      :class="{ 'error-boxWrap': $v.dues.checkingnumber.$error && $v.dues.checkingnumber.$dirty }">
                      <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">
                        {{ translations.tcBankAccountNumber }}
                      </h3>
                      <b-form-input id="type-text14" v-model="dues.checkingnumber" @blur="$v.dues.checkingnumber.$touch"
                        class="flex-0 g-select" :placeholder="dues.eftAccountNumberDisplayField" type="text"
                        maxlength="75"></b-form-input>
                      <span v-if="$v.dues.checkingnumber.$error && $v.dues.checkingnumber.$dirty">{{
                        errorMsg.reqField
                      }}</span>
                    </div>
                    <div class="mb-3"
                      :class="{ 'error-boxWrap': $v.dues.checkingnumber2.$error && $v.dues.checkingnumber2.$dirty }">
                      <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">
                        {{ translations.tcReEnterBankAccountNumber }}
                      </h3>
                      <b-form-input id="type-text15" v-model="dues.checkingnumber2"
                        @blur="$v.dues.checkingnumber2.$touch" class="flex-0 g-select" type="text"
                        maxlength="75"></b-form-input>
                      <span v-if="$v.dues.checkingnumber2.$dirty">
                        <span v-if="!$v.dues.checkingnumber2.required">{{ errorMsg.reqField }}</span>
                        <span v-if="!$v.dues.checkingnumber2.sameAsCheckingnumber && dues.checkingnumber2">Number does
                          not
                          match</span>
                      </span>
                    </div>

                  </template>
                  <!-- /ACH -->
                  <div class="mt-4">
                    <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">
                      {{ translations.tcPayerInformation }}
                    </h3>
                    <div id="donationCredit">
                      <b-form-group id="rdbPayerInfo">
                        <b-form-radio v-model="dues.payer" name="payerInfo" :value="individualProfile.ind_key">{{
                          this.individualProfile.formal_name
                        }}</b-form-radio>
                        <b-form-radio v-model="dues.payer" v-if="!userEqualIndividual" name="payerInfo"
                          :value="userIndKey">{{
                            this.userName }}</b-form-radio>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="mt-4">
                    <div class="mb-3" :class="{ 'error-boxWrap': $v.dues.address1.$error && $v.dues.address1.$dirty }">
                      <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">{{ translations.tcAddress1 }}</h3>
                      <b-form-input id="type-text9" v-model="dues.address1" @blur="$v.dues.address1.$touch"
                        class="flex-0 g-select" type="text" maxlength="75"></b-form-input>
                      <span v-if="$v.dues.address1.$error && $v.dues.address1.$dirty">{{ errorMsg.reqField }}</span>
                    </div>
                    <div class="mb-3" :class="{ 'error-boxWrap': $v.dues.city.$error && $v.dues.city.$dirty }">
                      <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">{{ translations.tcCity }}</h3>
                      <b-form-input id="type-text10" v-model="dues.city" @blur="$v.dues.city.$touch"
                        class="flex-0 g-select" type="text" maxlength="75"></b-form-input>
                      <span v-if="$v.dues.city.$error && $v.dues.city.$dirty">{{ errorMsg.reqField }}</span>
                    </div>
                    <div class="mb-3" :class="{ 'error-boxWrap': $v.dues.state.$error && $v.dues.state.$dirty }">
                      <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">{{ translations.tcState }}</h3>
                      <b-form-select v-model="dues.state" @blur="$v.dues.state.$touch" :options="optionsStates"
                        class="form-control g-select flex-0 mr-3">
                        <template slot="first"></template>
                      </b-form-select>
                      <span v-if="$v.dues.state.$error && $v.dues.state.$dirty">{{ errorMsg.reqField }}</span>
                    </div>
                    <div class="mb-3" :class="{ 'error-boxWrap': $v.dues.zipcode.$error && $v.dues.zipcode.$dirty }">
                      <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">{{ translations.tcZip }}</h3>
                      <b-form-input id="type-text11" v-model="dues.zipcode" @blur="$v.dues.zipcode.$touch"
                        class="flex-0 g-select" type="text" maxlength="75"></b-form-input>
                      <span v-if="$v.dues.zipcode.$error && $v.dues.zipcode.$dirty">
                        <span v-if="!$v.dues.zipcode.required">{{ errorMsg.reqField }}</span>
                        <span v-if="!$v.dues.zipcode.maxLength">No more than 10 digits are allowed.</span>
                        <span v-if="!$v.dues.zipcode.minLength">At least 5 digits are required.</span>
                        <span v-if="!$v.dues.zipcode.pattern">
                          Invalid format, correct format is 12345 or 12345-6789.</span>
                      </span>
                    </div>
                    <div class="mb-3" :class="{ 'error-boxWrap': $v.dues.email.$error && $v.dues.email.$dirty }">
                      <h3 class="flex-2 mr-3 text-left font-style-2 font-style-2--pb">{{ translations.tcEmail }}</h3>
                      <b-form-input id="type-text18" v-model="dues.email" @blur="$v.dues.email.$touch"
                        class="flex-0 g-select" type="text" maxlength="75"></b-form-input>
                      <!-- <span v-if="$v.dues.email.$error && $v.dues.email.$dirty">{{ errorMsg.reqField }}</span> -->
                      <span v-if="$v.dues.email.$dirty">
                        <span v-if="!$v.dues.email.required">{{ errorMsg.reqField }}</span>
                        <span v-if="!$v.dues.email.email">Invalid Email Address</span>
                      </span>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <div id="cvcInfo" v-if="dues.paymenttype === 'CCD'">
                  <h3>{{ translations.tcCardVerificationCode }}</h3>
                  <p>{{ translations.tcCVCDescription }}</p>
                  <p>{{ translations.tcCVCLocation }}</p>
                  <ul class="mb-1">
                    <li class="ml-1">
                      {{ translations.tcCVCAmexLocation }}
                    </li>
                    <li class="ml-1">
                      {{ translations.tcCVCDiscoverMasterCardVisaLocation }}
                    </li>
                  </ul>
                  <img src="@/assets/cvc_visa.gif" class="mb-1 mr-2" :alt="translations.tcCVCLocationOnVisa" />
                  <img src="@/assets/cvc_amex.gif" class="mb-1" :alt="translations.tcCVCLocationOnAmex" />
                </div>
                <div id="routingInfo" v-if="dues.paymenttype === 'ACH'">
                  <h3>{{ translations.tcBankRoutingAndAccountNumber }}</h3>
                  <p>{{ translations.tcBankRoutingAndAccountNumberLocationOnCheck }}</p>
                  <img src="@/assets/checkRouting.gif" class :alt="translations.tcCheckRoutingNumLocation" />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="">
            <b-button @click="handleBack2Click" v-if="!isMonthly" variant="primary"
              class="mr-3 w-100-sd mb-3 mb-sm-0">{{
                translations.tcBack }}
            </b-button>
            <b-button @click="handleNext2Click" variant="primary" class="mr-3 w-100-sd mb-3 mb-sm-0">{{
              translations.tcNext }}
            </b-button>
            <b-button @click="handleCancelClick" variant="tertiary" class="w-100-sd mb-sm-0">{{ translations.tcCancel }}
            </b-button>
          </div>
        </div>
        <!-- /STEP 2 -->
        <!-- STEP 3 -->
        <div id="Step3" v-if="currentStep === 3">
          <div>
            <b-row>
              <b-col sm="12">
                <div class="mb-2">
                  <div v-if="errMessage.length" class="errorDiv mb-4">
                    <ul>
                      <li>{{ errMessage }}</li>
                    </ul>
                  </div>
                  <template v-if="!isMonthly">
                    <strong>{{ translations.tcDuesPaymentReview }}</strong> -
                    {{ translations.tcDuesPaymentReviewDescription }}
                  </template>
                </div>
                <div>
                  <template v-if="!isMonthly">
                    <div v-if="dues.membertype === 'Gideon Member' ||
                      (!!individualProfile.spouse_ind_key
                        && dues.membertype === 'Auxiliary Member')">
                      <div class="detail-list__row">
                        <span class="strong_label">{{ translations.tcGideonDues }}:</span>
                        {{ formatDollars(dues.gideonPaymentAmount) }} {{ translations.tcFor }}
                        {{ dues.membertype === 'Gideon Member'
                          ? individualProfile.member_number
                          : individualProfile.spouse_member_number }}
                        {{ dues.membertype === 'Gideon Member'
                          ? individualProfile.formal_name
                          : individualProfile.spouse_formal_name }}
                      </div>
                    </div>
                    <div v-if="dues.membertype === 'Auxiliary Member' ||
                      (!!individualProfile.spouse_ind_key && dues.membertype === 'Gideon Member')
                    ">
                      <div class="detail-list__row">
                        <span class="strong_label">{{ translations.tcAuxiliaryDues }}:</span>
                        {{ formatDollars(dues.auxPaymentAmount) }} {{ translations.tcFor }}
                        {{
                          dues.membertype === 'Auxiliary Member'
                            ? individualProfile.member_number
                            : individualProfile.spouse_member_number
                        }}
                        {{
                          dues.membertype === 'Auxiliary Member'
                            ? individualProfile.formal_name
                            : individualProfile.spouse_formal_name
                        }}
                      </div>
                    </div>
                  </template>
                  <div class="detail-list__row">
                    <span class="strong_label">{{ translations.tcTotalPaymentAmount }}:</span>
                    {{ isMonthly ? formatDollars(memberTotalPayment * 12) : formatDollars(memberTotalPayment) }}
                  </div>
                  <div v-if="isMonthly">
                    <div class="detail-list__row">
                      <span class="strong_label">Monthly Installment:</span>
                      {{ formatDollars(memberTotalPayment) }}
                    </div>
                    <div class="detail-list__row">
                      <span class="strong_label">Selected Withdrawal Date:</span>
                      {{ dues.withdrawDate === 1 ? '1st of the Month' : '15th of the Month' }}
                    </div>
                  </div>
                  <div class="detail-list__row">
                    <span class="strong_label">{{ translations.tcPaymentType }}:</span>
                    {{ dues.paymenttype === 'ACH' ? translations.tcACH : translations.tcCreditCard }}
                  </div>
                  <div class="detail-list__row">
                    <span class="strong_label">{{ translations.tcNameOnAccount }}:</span>
                    {{ dues.nameonaccount }}
                  </div>
                  <template v-if="dues.paymenttype === 'CCD'">
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcCreditCardNumber }}:</span>
                      {{ displayCardNum }}
                    </div>
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcExpirationDate }}:</span>
                      {{ dues.expiremonth }}/{{ dues.expireyear }}
                    </div>
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcCardholderName }}:</span>
                      {{ dues.nameonaccount }}
                    </div>
                  </template>
                  <template v-if="dues.paymenttype === 'ACH'">
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcBankRoutingNumber }}:</span>
                      {{ dues.routingnumber }}
                    </div>
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcBankAccountNumber }}:</span>
                      {{ dues.checkingnumber }}
                    </div>
                  </template>
                  <div class="detail-list__row">
                    <span class="strong_label">{{ translations.tcAddress }}:</span>
                    {{ dues.address1 }}
                  </div>
                  <div class="detail-list__row">
                    <span class="strong_label"></span>
                    {{ dues.city }}, {{ dues.state }} {{ dues.zipcode }}
                  </div>
                  <div class="detail-list__row">
                    <span class="strong_label">{{ translations.tcEmail }}:</span>
                    {{ dues.email }}
                  </div>
                </div>
                <div class="mt-2" v-if="!isMonthly">
                  <strong>{{ translations.tcDuesPaymentReview }}</strong> -
                  {{ translations.tcDuesPaymentReviewDescription }} <br />{{
                    translations.tcPaymentPageIsSecureDescription
                  }}
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mt-4">
            <b-button @click="handleBack3Click" variant="primary" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
              translations.tcBack
            }}</b-button>
            <b-button @click="handleNext3Click" variant="primary" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
              isMonthly ? 'Renew Membership' : translations.tcPayDues
            }}</b-button>
            <b-button @click="handleCancelClick" variant="tertiary" class="flex-0 w-100-sd mb-sm-0">{{
              translations.tcCancel
            }}</b-button>
          </div>
        </div>
        <!-- /STEP 3 -->
        <!-- STEP 4 -->
        <div id="Step4" v-if="currentStep === 4">
          <div class="d-flex">
            <b-row>
              <b-col sm="12">
                <div>
                  <h1>{{ translations.tcThankYou }}</h1>
                  <template v-if="!isMonthly">
                    <div v-if="dues.membertype === 'Gideon Member' ||
                      (!!individualProfile.spouse_ind_key && dues.membertype === 'Auxiliary Member')
                    ">
                      <div class="detail-list__row">
                        <span class="strong_label">{{ translations.tcGideonDues }}:</span>
                        {{ formatDollars(dues.gideonPaymentAmount) }} {{ translations.tcFor }}
                        {{
                          dues.membertype === 'Gideon Member'
                            ? individualProfile.member_number
                            : individualProfile.spouse_member_number
                        }}
                        {{
                          dues.membertype === 'Gideon Member'
                            ? individualProfile.formal_name
                            : individualProfile.spouse_formal_name
                        }}
                      </div>
                    </div>
                    <div v-if="dues.membertype === 'Auxiliary Member' ||
                      (!!individualProfile.spouse_ind_key && dues.membertype === 'Gideon Member')
                    ">
                      <div class="detail-list__row">
                        <span class="strong_label">{{ translations.tcAuxiliaryDues }}:</span>
                        {{ formatDollars(dues.auxPaymentAmount) }} {{ translations.tcFor }}
                        {{
                          dues.membertype === 'Auxiliary Member'
                            ? individualProfile.member_number
                            : individualProfile.spouse_member_number
                        }}
                        {{
                          dues.membertype === 'Auxiliary Member'
                            ? individualProfile.formal_name
                            : individualProfile.spouse_formal_name
                        }}
                      </div>
                    </div>
                  </template>
                  <div class="detail-list__row">
                    <span class="strong_label">{{ translations.tcTotalPaymentAmount }}:</span>
                    {{ isMonthly ? formatDollars(memberTotalPayment * 12) : formatDollars(memberTotalPayment) }}
                  </div>
                  <div v-if="isMonthly">
                    <div class="detail-list__row">
                      <span class="strong_label">Monthly Installment:</span>
                      {{ formatDollars(memberTotalPayment) }}
                    </div>
                    <div class="detail-list__row">
                      <span class="strong_label">Selected Withdrawal Date:</span>
                      {{ dues.withdrawDate === 1 ? '1st of the Month' : '15th of the Month' }}
                    </div>
                  </div>
                  <div class="detail-list__row">
                    <span class="strong_label">{{ translations.tcPaymentType }}:</span>
                    {{ dues.paymenttype === 'ACH' ? translations.tcACH : translations.tcCreditCard }}
                  </div>
                  <div class="detail-list__row">
                    <span class="strong_label">{{ translations.tcNameOnAccount }}:</span>
                    {{ dues.nameonaccount }}
                  </div>
                  <template v-if="dues.paymenttype === 'CCD'">
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcCreditCardNumber }}:</span>
                      {{ displayCardNum }}
                    </div>
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcExpirationDate }}:</span>
                      {{ dues.expiremonth }}/{{ dues.expireyear }}
                    </div>
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcCardholderName }}:</span>
                      {{ dues.nameonaccount }}
                    </div>
                  </template>
                  <template v-if="dues.paymenttype === 'ACH'">
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcBankRoutingNumber }}:</span>
                      {{ dues.routingnumber }}
                    </div>
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcBankAccountNumber }}:</span>
                      {{ dues.checkingnumber }}
                    </div>
                  </template>
                  <div class="detail-list__row">
                    <span class="strong_label">{{ translations.tcAddress }}:</span>
                    {{ dues.address1 }}
                  </div>
                  <div class="detail-list__row">
                    <span class="strong_label"></span>
                    {{ dues.city }}, {{ dues.state }} {{ dues.zipcode }}
                  </div>
                  <div class="detail-list__row">
                    <span class="strong_label">{{ translations.tcEmail }}:</span>
                    {{ dues.email }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>

          <div class="d-flex mt-4">
            <b-button @click="handleCancelClick" variant="tertiary" class="flex-0 w-100-sd mb-sm-0">{{
              translations.tcFinished
            }}</b-button>
          </div>
        </div>
        <!-- /STEP 4 -->
      </div>
    </section>
  </page-body>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CreditCardsOnFile from "@/components/CreditCardsOnFile.vue";
import date_data from '@/json/date.json'
import { numericMixin } from '@/mixins/numericMixin'
import pageBody from '@/components/page-components/PageBody.vue'
import { translationMixin } from '@/mixins/translationMixin'
import { required, numeric, sameAs, minLength, maxLength, email } from 'vuelidate/lib/validators'
import SecurityBanner from '@/components/SecurityBanner'

export default {
  name: 'member-dues',
  mixins: [translationMixin, numericMixin],

  data() {
    return {
      selectedCreditCard: null,
      translations: {},
      errMessage: [],
      currentStep: 1,
      optionsPaymentTypes: [],
      optionsYesNoGideon: [],
      optionsYesNo: [],
      optionsAutopay: [],
      dues: {
        address1: '',
        autopay: null,
        autopayspouse: false,
        auxPayment: 1,
        auxPaymentAmount: 0,
        cardcvc: '',
        cardnumber: '',
        cardNumberDisplayField: '',
        checkingnumber2: '',
        checkingnumber: '',
        city: '',
        cpi_key: '',
        creditDonation: null,
        cst_key: '',
        donationtype: '',
        eftAccountNumberDisplayField: '',
        email: '',
        expiremonth: null,
        expireyear: null,
        gideonPayment: 1,
        gideonPaymentAmount: 0,
        gideonPaymentAmountMonthly: 0,
        isautopay: false,
        mbr_key: '',
        membertype: '',
        nameonaccount: '',
        payer: null,
        paymentinstrument: '0',
        paymenttype: null,
        recurrence: null,
        routingnumber: '',
        routingnumberDisplay: '',
        spouse_cpi_key: '',
        spouse_key: '',
        spouse_mbr_key: '',
        state: null,
        totalPaymentAmount: this.memberTotalPayment,
        transactionDate: null,
        withdrawDate: 0,
        zipcode: ''
      },
      optionsTransactionDate: [
        { text: '1st of the Month', value: 1, notEnabled: true },
        { text: '15th of the Month', value: 15, notEnabled: true }
      ],
      errorMsg: {
        reqField: "Required field. Please enter a valid value."
      },
      isDuesSeason: false,
      isPendingDropDate: false,
    }
  },
  validations: {
    dues: {
      cardnumber: { required, numeric, minLength: minLength(15) },
      expiremonth: {
        required,
        isExpiryValid(value, vm) {
          if (!value || !vm.expireyear) {
            return true
          }
          const currentYear = new Date().getFullYear()
          const currentMonth = new Date().getMonth() + 1 // getMonth() returns 0-11 for Jan-Dec
          const expiryYear = parseInt(vm.expireyear, 10)
          const expiryMonth = parseInt(value, 10)
          return expiryYear > currentYear || (expiryYear === currentYear && expiryMonth >= currentMonth)
        }
      },
      expireyear: {
        required,
        isExpiryValid(value, vm) {
          if (!value || !vm.expiremonth) {
            return true
          }
          return !this.hasCreditCardExpired(parseInt(vm.expiremonth, 10), parseInt(value, 10))
        }
      },
      nameonaccount: { required },
      cardcvc: { required, numeric },
      address1: { required },
      city: { required },
      state: { required },
      zipcode: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(10),
        pattern(value) {
          return /^\d{5}(-\d+)?$/.test(value)
        }
      },
      checkingnumber: { required },
      checkingnumber2: { required, sameAsCheckingnumber: sameAs('checkingnumber') },
      routingnumber: { required },
      email: { required, email },
      paymenttype: { required }
    }
  },

  methods: {
    ...mapActions({
      loadDonorCardsOnFile: 'user/loadDonorCardsOnFile',
      loadIndividualProfileHeader: 'membership/loadIndividualProfileHeader',
      loadUsStates: 'membership/loadUsStates',
      saveAutopay: 'membership/saveAutopay',
      saveDues: 'membership/saveDues',
      setLoadingStatus: 'menu/setLoadingStatus',
      setIsIndividualProfileLifeTime: 'membership/setIsIndividualProfileLifeTime',
      setSelectedCardOnFile: 'user/setSelectedCardOnFile'
    }),

    formatDollars(value) {
      return Number(value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })
    },

    //To Check if zipCode is valid or not
    isValidZipCode(zipCode) {
      return /^\d{5}(-\d+)?$/.test(zipCode)
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      })

    },
    handleCancelClick() {
      this.$router.push({ name: 'member-profile' })
    },
    handleNext1Click() {
      this.dues.gideonPaymentAmount = this.dues.gideonPayment ? Number(this.translations.tcGideonDuesAmount) : 0
      this.dues.auxPaymentAmount = this.dues.auxPayment ? Number(this.translations.tcAuxiliaryDuesAmount) : 0
      this.currentStep = 2
      this.scrollToTop()
    },
    handleCardSelection(evt) {
      this.dues.cardnumber = 'xxxxxxxxxxxx' + evt.pit_last_four
      let exp = evt.pit_expiration.split('-')
      this.dues.expiremonth = exp[0]
      this.dues.expireyear = exp[1]
      this.dues.cardcvc = ''
    },
    handleCardNumberClick() {
      this.dues.cardnumber = ''
      this.dues.expiremonth = ''
      this.dues.expireyear = ''
      this.dues.cardcvc = ''
      this.selectedCreditCard = null
      this.setSelectedCardOnFile(null)
    },
    //To Check if year and month are valid
    isExpiryValid(expiryYear, expiryMonth) {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth() + 1 // January is 0

      expiryYear = parseInt(expiryYear, 10)
      expiryMonth = parseInt(expiryMonth, 10)

      return expiryYear > currentYear || (expiryYear === currentYear && expiryMonth >= currentMonth) || !this.isNewCCSelected
    },
    async handleNext2Click() {
      this.$v.dues.$touch()

      if (this.dues.paymenttype === 'CCD') {
        if (
          (this.dues.cardnumber === '' ||
            this.dues.nameonaccount === '' ||
            this.dues.expiremonth === null ||
            this.dues.expireyear === null ||
            this.dues.cardcvc === '' ||
            this.dues.address1 === '' ||
            this.dues.city === '' ||
            this.dues.state === null ||
            this.dues.zipcode === '' ||
            this.isExpiryValid(this.dues.expireyear, this.dues.expiremonth) === false ||
            !this.isValidZipCode(this.dues.zipcode)) && this.isNewCCSelected
        ) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorAllFieldsRequired,
            confirmButtonText: this.translations.tcOk || 'Ok'
          }).then(result => {
            return
          })
          return
        }
      } else {
        if (
          this.dues.nameonaccount === '' ||
          this.dues.routingnumber === '' ||
          this.dues.checkingnumber === '' ||
          this.dues.checkingnumber2 === ''
        ) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorAllFieldsRequired,
            confirmButtonText: this.translations.tcOk || 'Ok'
          }).then(result => {
            return
          })
          return
        }
        if (this.dues.checkingnumber !== this.dues.checkingnumber2) {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorCheckingNumsDoNotMatch,
            confirmButtonText: this.translations.tcOk || 'Ok'
          }).then(result => {
            return
          })
          return
        }
      }

      if (
        this.dues.address1 === '' ||
        this.dues.city === '' ||
        this.dues.state === null ||
        this.dues.zipcode === '' ||
        this.dues.email === ''
      ) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorAllFieldsRequired,
          confirmButtonText: this.translations.tcOk || 'Ok'
        }).then(result => {
          return
        })
        return
      }
      if (!this.isDuesFormInvalid) {
        this.currentStep = 3
        this.scrollToTop()
      }
    },
    handleBack2Click() {
      this.currentStep = 1
      this.scrollToTop()
    },
    async handleNext3Click() {
      const duesPayload = {
        address1: this.dues.address1,
        autopayspouse: this.dues.autopayspouse ? 1 : 0,
        auxPayment: this.dues.auxPayment,
        auxPaymentAmount: this.dues.auxPaymentAmount,
        cardcvc: this.dues.cardcvc,
        cardnumber: this.dues.cardnumber,
        checkingnumber: this.dues.checkingnumber,
        city: this.dues.city,
        creditDonation: this.dues.creditDonation,
        email: this.dues.email,
        expiremonth: this.dues.expiremonth,
        expireyear: this.dues.expireyear,
        gideonPayment: this.dues.gideonPayment,
        gideonPaymentAmount: this.dues.gideonPaymentAmount,
        mbr_key: this.individualProfile.ind_key,
        membertype: this.dues.membertype,
        nameonaccount: this.dues.nameonaccount,
        payer: this.dues.payer,
        transactionDate: this.dues.transactionDate,
        paymenttype: this.dues.paymenttype,
        routingnumber: this.dues.routingnumber,
        spouse_key: this.individualProfile.spouse_ind_key,
        state: this.dues.state,
        totalPaymentAmount: this.memberTotalPayment,
        userIndKey: this.userIndKey,
        zipcode: this.dues.zipcode
      }

      const autoPayLoad = {
        cpi_key: this.dues.cpi_key,
        cst_key: this.dues.cst_key,
        mbr_key: this.individualProfile.ind_key,
        spouse_key: this.individualProfile.spouse_ind_key,
        spouse_mbr_key: this.dues.spouse_mbr_key,
        paymentinstrument: '0',
        paymenttype: this.dues.paymenttype,
        isautopay: 1,
        address1: this.dues.address1,
        autopayspouse: this.dues.autopayspouse ? 1 : 0,
        cardcvc: this.dues.cardcvc,
        cardnumber: this.dues.cardnumber,
        checkingnumber: this.dues.checkingnumber,
        checkingnumber2: this.dues.checkingnumber2,
        routingnumber: this.dues.routingnumber,
        city: this.dues.city,
        state: this.dues.state,
        nameonaccount: this.dues.nameonaccount,
        zipcode: this.dues.zipcode,
        email: this.dues.email,
        expiremonth: this.dues.expiremonth,
        expireyear: this.dues.expireyear
      }

      let result = true
      let response = false

      const updatedPayload = this.isMonthly
        ? { ...autoPayLoad, withdrawDate: this.dues.withdrawDate, frequency: 'Monthly' }
        : {
          ...duesPayload
        }

      // Making APi conditional based on renew membership flag
      const apiFunc = this.isMonthly ? this.saveAutopay : this.saveDues

      await Promise.all([this.setLoadingStatus(true), (response = await apiFunc(updatedPayload))]).then(() => {
        if (!response || response.data.response === '0') {
          this.errMessage = !response ? 'Error paying member dues' : response.data.message
          console.error('ERROR: ', this.errMessage)
          result = false
        }
        this.setLoadingStatus(false)
        const successMsg = this.isMonthly
          ? 'Monthly Installments have been completed'
          : this.translations.tcMemberDuesSaved
        const htmlContent = result
          ? `
          <div class='swal2-html-container'>${result ? successMsg : this.translations.tcErrorDuringSave}</div>
         <div id="swal-success-redirect-donation-gift" class="swal-text-donation"> Please select <strong> "Make A Gift" </strong> if you would like to give a donation in addition to your membership renewal</div>
          `
          : `<div class='swal2-html-container'>${result ? successMsg : this.translations.tcErrorDuringSave}</div>`

        this.$swal({
          icon: result ? 'success' : 'error',
          html: htmlContent,
          showCancelButton: true,
          showConfirmButton: result,
          confirmButtonText: 'Make A Gift',
          cancelButtonText: result ? 'Finished' : 'Try Again',
          cancelButtonColor: result ? 'green' : 'red'
        }).then(async ({ isConfirmed, isDismissed }) => {
          if (result && this.userEqualIndividual) {
            this.loadIndividualProfileHeader(this.userIndKey)
          }

          if (isConfirmed) {
            // redirecting to donations page
            await this.$router.push({ name: 'donations' })
            this.$swal.close()
          }
          if (isDismissed && result) {
            this.currentStep = 4
            this.$swal.close()
          }
        })
      })
    },

    handleBack3Click() {
      this.errMessage = []
      this.currentStep = 2
      this.scrollToTop()
    },
    payerChanged() {
      this.dues.address1 =
        this.dues.payer === this.individualProfile.ind_key ? this.individualProfile.address1 : this.demograph.address1
      this.dues.city =
        this.dues.payer === this.individualProfile.ind_key ? this.individualProfile.city : this.demograph.city
      this.dues.state =
        this.dues.payer === this.individualProfile.ind_key ? this.individualProfile.state : this.demograph.state
      this.dues.zipcode =
        this.dues.payer === this.individualProfile.ind_key
          ? this.individualProfile.postal_code
          : this.demograph.postal_code
      this.dues.email =
        this.dues.payer === this.individualProfile.ind_key ? this.individualProfile.email : this.demograph.email
      if (
        this.dues.membertype === 'Gideon Member' &&
        (!this.individualProfile.spouse_ind_key ||
          !this.individualProfile.spouse_member_type_key ||
          this.individualProfile.spouse_paid_flag)
      ) {
        this.dues.auxPayment = 0
        this.dues.auxPaymentAmount = 0
      }
      if (
        (this.dues.membertype === 'Auxiliary Member' && !this.individualProfile.spouse_ind_key) ||
        this.individualProfile.spouse_paid_flag
      ) {
        this.dues.gideonPayment = 0
        this.dues.gideonPaymentAmount = 0
      }
    },
    loadTranslations() {
      this.optionsPaymentTypes = [
        { value: null, text: this.translations.tcPaymentType, disabled: true },
        { value: 'ACH', text: this.translations.tcACH },
        { value: 'CCD', text: this.translations.tcCreditCard }
      ]

      this.optionsYesNoGideon = [
        { text: this.translations.tcYes, value: 1 },
        { text: this.translations.tcNo, value: 0, disabled: true }
      ]

      this.optionsYesNo = [
        { text: this.translations.tcYes, value: 1 },
        { text: this.translations.tcNo, value: 0, disabled: this.individualProfile.spouse_paid_flag }
      ]
      // NOTE: this optionsAutopay doesn't appear to be working. An inline
      // value is provided with the same value of 'html'. (Refer to element cbAutopay.)
      this.optionsAutopay = [
        {
          html: `<strong>${this.translations.tcAutoPay}: ${this.translations.tcAnnualAutopayDescription}</strong>`,
          value: true
        }
      ]
    },
    async setPaymentInfoFlags() {
      this.isDuesSeason = this.paymentInfo.isPayDuesLinkSupposeToBeVisible
      this.isPendingDropDate = this.paymentInfo.mbrPendingDropDate !== null
    }
  },
  watch: {
    'dues.payer': function (val, oldVal) {
      this.payerChanged()
    },
    '$route.path'(newValue) {
      if (!newValue.includes('monthly')) {
        this.currentStep = 1;
      }
    },
  },
  mounted() {
    if (this.$route.path.includes('monthly') && this.currentStep < 2) {
      this.currentStep = 2
    }
  },
  computed: {
    ...mapGetters({
      convertedUsStates: 'membership/convertedUsStates',
      demograph: 'user/demograph',
      donorCardsOnFile: 'user/donorCardsOnFile',
      fiscalYear: 'membership/fiscalYear',
      individualProfile: 'membership/individualProfile',
      individualProfileHeader: 'membership/individualProfileHeader',
      isIndividualProfileMemberLifetime: 'membership/isIndividualProfileMemberLifetime',
      members: 'membership/members',
      paymentInfo: 'membership/paymentInfo',
      prefCulture: 'user/userPreferredCulture',
      userImpersonation: 'user/userImpersonation',
      userIndKey: 'user/userId',
      userLogin: 'user/userLogin',
      userName: 'user/userName',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
      usStates: 'membership/usStates'
    }),
    isNewCCSelected() {
      return this.selectedCreditCard === null
    },
    isDuesFormInvalid() {

      const isCommonFormInvalid = this.$v.dues.address1.$invalid || this.$v.dues.zipcode.$invalid ||
        this.$v.dues.city.$invalid || this.$v.dues.state.$invalid || this.$v.dues.email.$invalid
      const isCardFieldsInvalid = this.$v.dues.cardnumber.$invalid || this.$v.dues.nameonaccount.$invalid ||
        this.$v.dues.expiremonth.$invalid || this.$v.dues.cardcvc.$invalid || this.$v.dues.expireyear.$invalid ||
        this.$v.dues.expiremonth.$invalid
      const isACHFieldsInvalid = this.$v.dues.nameonaccount.$invalid || this.$v.dues.routingnumber.$invalid ||
        this.$v.dues.checkingnumber.$invalid || this.$v.dues.checkingnumber2.$invalid
      const isPaymentTypeCard = this.dues.paymenttype === 'CCD'
      return isPaymentTypeCard ? isCommonFormInvalid || isCardFieldsInvalid : isCommonFormInvalid ||
        isACHFieldsInvalid
    },
    isAllowPage() {
      //To allow user to fill renewal case when the payment is dropped by any reason
      let ineligible = true
      const findMember = this.members.find((member) => member.ind_key === this.individualProfile.ind_key)

      if (!!findMember && findMember.is_installment_member === false) {
        ineligible = this.isIndividualProfileMemberLifetime || !!findMember.pending_drop_date || !!this.individualProfile.paid_flag
      } else {
        ineligible = this.isIndividualProfileMemberLifetime || !!this.individualProfile.paid_flag
      }
      return !ineligible
    },
    isMonthly() {
      const checkMonthly = this.$route.path.includes('monthly')
      return checkMonthly
    },
    // FormOptions
    optionsExpireYear() {
      const currentYear = new Date().getFullYear()
      let years = Array.from(new Array(14), (val, index) => {
        return { value: currentYear + index, text: currentYear + index }
      })
      return [{ value: null, text: this.translations.tcYear, disabled: true }, ...years]
    },
    optionsExpireMonth() {
      const objDate = new Date(2022, 0, 2, 12, 0, 0) // create temp. Date object, which will be set in each loop for the translation
      let months = date_data.months.map(x => {
        objDate.setMonth(x.value - 1) // set Date object to the month from the loop
        const text = objDate.toLocaleString(this.prefCulture, { month: 'long' })
        return { value: x.value.length === 1 ? '0' + x.value : x.value, text }
      })
      return [{ value: null, text: this.translations.tcMonth, disabled: true }, ...months]
    },
    optionsStates() {
      return [{ value: null, text: this.translations.tcState, disabled: true }, ...this.convertedUsStates]
    },
    // END FormOptions
    userEqualIndividual() {
      return this.userIndKey === this.individualProfile.ind_key
    },
    memberTotalPayment() {
      if (this.isMonthly) {
        let installment = 0

        if (this.userEqualIndividual && this.individualProfile.member_type === 'Gideon Member') {
          installment = Number(this.translations.tcGideonDuesAmount) / 12
        } else if (this.userEqualIndividual && this.individualProfile.member_type === 'Auxiliary Member') {
          installment = Number(this.translations.tcAuxiliaryDuesAmount) / 12
        }

        return installment
      } else {
        return (
          parseInt(this.dues.gideonPaymentAmount, 10) +
          parseInt(this.dues.auxPaymentAmount, 10)
        )
      }
    },
    displayCardNum() {
      return this.dues.cardnumber.length > 0
        ? 'xxxxxxxxxxxx' + this.dues.cardnumber.substring(this.dues.cardnumber.length - 4, this.dues.cardnumber.length)
        : ''
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([this.getViewTranslations(), this.loadUsStates(), this.setIsIndividualProfileLifeTime()])
      this.loadTranslations()
      this.dues.creditDonation = this.individualProfile.ind_key
      this.dues.payer = this.individualProfile.ind_key
      this.dues.membertype = this.individualProfile.member_type
      this.dues.gideonPaymentAmountMonthly = Number(this.translations.tcGideonDuesAmount)
      this.dues.withdrawDate = !!this.paymentInfo.withdrawDate ? this.paymentInfo.withdrawDate : 0
      await this.loadDonorCardsOnFile({ ind_key: this.individualProfile.ind_key, origin: 'TheConnection', check: true })

      // if (this.paymentInfo.Membership.length !== 0) {
      //   // Only for editing an existing autopay
      //   this.dues.spouse_mbr_key = !!membership.spouseMbrKeyField ? membership.spouseMbrKeyField : ''
      //   this.dues.spouse_cpi_key = !!membership.spouseCpiKeyField ? membership.spouseCpiKeyField : ''
      // } else {
      this.dues.cst_key = this.individualProfile.ind_key
      // }
      this.setPaymentInfoFlags()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    CreditCardsOnFile: CreditCardsOnFile,
    pageBody: pageBody,
    SecurityBanner
  }
}
</script>
<style>
.swal-text-donation {
  margin-top: 15px;
  margin-bottom: 5px;
}
</style>
<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.detailsTopMargin {
  margin-top: 8px;
}

.errorDiv {
  color: red;
  font-weight: bold;

  ul {
    list-style-type: none;

    li:nth-child(1n + 2) {
      margin-left: 20px;
    }
  }
}

.offset {
  margin: 10px 0 0 20px;
}

.strong_label {
  width: 250px;
  font-weight: bold;
  display: inline-block;
}

.inb {
  display: inline-block;
}

.divDivider {
  text-align: center;
  align-content: center;
}

hr {
  max-width: 100%;
  margin: 25px 0;
  background-color: #979797;
  text-align: center;
}

input[type='number'] {
  height: auto;
  max-width: 95px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.inputCVC {
  width: 100px;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox3 {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.font-style-2.font-style-2--pb {
  line-height: 22px;
  padding-bottom: 3px;
}

.form-control.g-select.custom-select {
  background: #fff;
}

.renewalForm .error-boxWrap input,
.error-boxWrap select,
.error-boxWrap .g-select:not(.is-invalid) {
  border: 1px solid red !important;
  border-color: 1px solid red !important;
  box-shadow: 0 0 5px red;
}

.renewalForm .error-boxWrap span {
  color: red;
}

.radio-group-date {
  .custom-control-label {
    margin-bottom: 6px;
  }
}

.detail-list {
  margin-bottom: 30px;
}

.detail-list .strong_label {
  font-weight: bold;
  min-width: 200px;
  display: inline-flex;
  color: #262626;
  flex-wrap: wrap;
  max-width: 200px;
  line-height: 20px;
}

.detail-list__row {
  margin-bottom: 6px;
  color: #262626;
}
</style>
